import { forwardRef } from 'react'
import type {
  ComponentPropsWithoutRef,
  ElementRef,
  HTMLAttributes,
} from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@chaseweb/utils/cn'
import { Slot } from '@radix-ui/react-slot'
import { Button, CardProfile, Heading } from '../../atoms'

const rootVariants = cva('', {
  variants: {
    variant: {
      primary: 'tw-bg-blue40 tw-bg-promo-primary',
      secondary: 'tw-bg-grey90',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
})

interface RootProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof rootVariants> {}

const Root = forwardRef<HTMLDivElement, RootProps>(
  ({ className, children, variant, ...props }, ref) => {
    return (
      <div
        data-ui-component={Root.displayName}
        data-variant={variant}
        className={cn(
          'w-w-full tw-relative tw-max-w-screen-2xl',
          {
            'tw-group/promo-primary': variant === 'primary',
            'tw-group/promo-secondary': variant === 'secondary',
          },
          className,
        )}
        ref={ref}
        {...props}
      >
        <CardProfile
          shape="rounded-lg"
          className="tw-absolute -tw-z-10 tw-h-full tw-w-full tw-bg-blue20"
        >
          <div
            className={cn(rootVariants({ variant }), 'tw-h-full tw-w-full')}
          />
        </CardProfile>
        <div
          className={cn(
            'tw-z-10 tw-flex tw-flex-col',
            'max-md:tw-overflow-hidden max-md:tw-rounded-3xl',
            'tw-grid md:tw-mx-auto md:tw-max-w-[1280px] md:tw-grid-cols-2',
          )}
        >
          {children}
        </div>
      </div>
    )
  },
)
Root.displayName = 'Promo.Root'

const imageVariants = cva(
  'tw-w-full tw-justify-center md:tw-max-h-[640px] lg:tw-max-h-none',
  {
    variants: {
      variant: {
        fit: 'tw-object-contain md:tw-max-w-[320px] lg:tw-max-w-[450px]',
        overflow:
          'tw-object-contain md:-tw-mt-7 md:tw-max-w-[350px] lg:tw-max-w-[420px] xl:-tw-mt-14 xl:tw-max-w-[500px] ',
        background:
          'tw-bottom-0 tw-right-0 tw-top-0 tw-bg-right tw-object-cover md:tw-absolute md:tw-h-full md:tw-w-1/2 md:tw-rounded-r-3xl',
      },
    },
    defaultVariants: {
      variant: 'fit',
    },
  },
)

interface ImageProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof imageVariants> {
  asChild?: boolean
}

const ImageWrapper = forwardRef<HTMLDivElement, ImageProps>(
  ({ className, variant, asChild, ...props }, ref) => {
    const Comp = asChild ? Slot : 'div'
    return (
      <div className={cn('tw-flex tw-items-end tw-justify-center')}>
        <Comp
          ref={ref}
          data-ui-component={ImageWrapper.displayName}
          className={cn(imageVariants({ variant, className }))}
          {...props}
        />
      </div>
    )
  },
)

ImageWrapper.displayName = 'Promo.ImageWrapper'

const ContentWrapper = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div
      ref={ref}
      data-ui-component={ContentWrapper.displayName}
      className={cn(
        'tw-z-20 tw-flex tw-w-full tw-flex-col tw-justify-center tw-p-6 md:tw-p-12',
        className,
      )}
      {...props}
    >
      <div className="tw-text-left">{children}</div>
    </div>
  )
})

ContentWrapper.displayName = 'Promo.ContentWrapper'

const Title = forwardRef<
  ElementRef<typeof Heading>,
  PartialBy<ComponentPropsWithoutRef<typeof Heading>, 'type'>
>(({ className, type, ...props }, ref) => (
  <Heading
    type={type ?? 'h2'}
    ref={ref}
    data-ui-component={Title.displayName}
    className={cn(
      'tw-mb-4',
      'group-[&]/promo-primary:tw-text-white',
      className,
    )}
    {...props}
  />
))
Title.displayName = 'Promo.Title'

const Description = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      data-ui-component={Description.displayName}
      className={cn(
        'tw-mb-4',
        'group-[&]/promo-primary:tw-text-white',
        className,
      )}
      {...props}
    />
  ),
)
Description.displayName = 'Promo.Description'

const Cta = forwardRef<
  ElementRef<typeof Button>,
  ComponentPropsWithoutRef<typeof Button>
>(({ className, ...props }, ref) => (
  <Button
    ref={ref}
    data-ui-component={Cta.displayName}
    variant="secondary"
    className={cn(
      'tw-mt-2',
      'group-[&]/promo-primary:tw-border-none',
      className,
    )}
    {...props}
  />
))
Cta.displayName = 'Promo.Cta'

export { Root, ImageWrapper, ContentWrapper, Title, Description, Cta }
