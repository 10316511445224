'use client'

import { useSplitStore } from '@chaseweb/utils/split'
import { useDownloadConversionPixelsFragment } from '@chaseweb/utils/tracking'
import { useAttributionUrlLink } from '@chaseweb/utils/tracking/vendor'
import * as Portal from '@radix-ui/react-portal'
import { useCallback, useEffect, useRef, useState } from 'react'

import { DownloadPanel } from '@/components'
import { useChaseEnv } from '@/lib/hooks'
import { useConsentStore } from '@/lib/stores'

import type { TrackedLinkProps } from '../link'
import { Link } from '../link'

export const DownloadLink = (
  props: Omit<TrackedLinkProps, 'href' | 'trackingActionLabel'>,
) => {
  const env = useChaseEnv()
  const { AdvertisingAndTargeting } = useConsentStore((store) => store.consents)
  const { DownloadConversionPixelsFragment, triggerConversionPixels } =
    useDownloadConversionPixelsFragment({
      hasAdTargetingConsent: AdvertisingAndTargeting,
    })
  const [showDownloadPanel, setShowDownloadPanel] = useState<boolean>(false)
  const [isMobileDevice, setIsMobileDevice] = useState<boolean>(false)
  const client = useSplitStore((state) => state.clients.channel_type)
  const treatmentSlidePanel = client?.getTreatment('download_cta_slide_panel')

  const mainContainer = useRef<HTMLElement | null>(null)
  useEffect(() => {
    if (typeof navigator !== 'undefined') {
      const regex = /Mobi|Android|iPhone/i
      setIsMobileDevice(regex.test(navigator.userAgent))

      mainContainer.current = document.querySelector('main')
    }
  }, [])

  const downloadLink = useAttributionUrlLink({
    appsflyerLink: env?.openAppUrl ?? '',
    sitePrefix: process.env.NEXT_PUBLIC_BASE_PATH
      ? `${process.env.NEXT_PUBLIC_BASE_PATH.replace(/^\//, '')}/`
      : '',
    variant: 'download',
  })

  const toggleDownloadPanel = useCallback(() => {
    setShowDownloadPanel(!showDownloadPanel)
  }, [showDownloadPanel])

  const onClick: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      props.onClick && props.onClick(e)
      triggerConversionPixels()
      if (treatmentSlidePanel === 'on' && !isMobileDevice) {
        e.preventDefault()
        toggleDownloadPanel()
      }
    },
    [
      props,
      triggerConversionPixels,
      treatmentSlidePanel,
      isMobileDevice,
      toggleDownloadPanel,
    ],
  )

  if (!env?.openAppUrl || !downloadLink) {
    return <span className={props.className}>{props.children}</span>
  }

  return (
    <>
      {treatmentSlidePanel === 'on' && mainContainer && (
        <Portal.Root container={mainContainer.current} asChild>
          <DownloadPanel
            toggleDownloadPanel={toggleDownloadPanel}
            show={showDownloadPanel}
          />
        </Portal.Root>
      )}
      <Link
        {...props}
        onClick={onClick}
        href={downloadLink}
        trackingActionLabel="visitAppStore"
      >
        {props.children}
        <DownloadConversionPixelsFragment />
      </Link>
    </>
  )
}
