import { forwardRef } from 'react'
import type { HTMLAttributes } from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cn } from '@chaseweb/utils/cn'

const Root = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <header
        ref={ref}
        className={cn(
          `ui-stop-no-scroll tw-sticky tw-left-0 tw-top-0 tw-z-30 tw-flex tw-h-15 tw-w-full tw-shrink-0 tw-justify-center tw-self-start tw-bg-white lg:tw-h-24`,
          className,
        )}
        {...props}
      />
    )
  },
)
Root.displayName = 'Header.Root'

const Container = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'tw-flex tw-h-full tw-w-full tw-items-center tw-justify-between tw-px-2 md:tw-px-0 lg:tw-px-10',
          className,
        )}
        {...props}
      ></div>
    )
  },
)
Container.displayName = 'Header.Container'

interface LogoWrapperProps extends HTMLAttributes<HTMLDivElement> {
  asChild?: boolean
}
const LogoWrapper = forwardRef<HTMLDivElement, LogoWrapperProps>(
  ({ className, asChild, ...props }, ref) => {
    const Comp = asChild ? Slot : 'div'
    return (
      <Comp
        ref={ref}
        className={cn(
          'tw-relative tw-flex tw-max-h-4 tw-shrink-0 tw-items-center lg:tw-max-h-6',
          className,
        )}
        {...props}
      />
    )
  },
)
LogoWrapper.displayName = 'Header.LogoWrapper'

const Content = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'tw-flex tw-h-full tw-max-h-6 tw-w-full tw-items-center tw-justify-end lg:tw-max-h-12',
          className,
        )}
        {...props}
      ></div>
    )
  },
)
Content.displayName = 'Header.Content'

export { Root, Container, Content, LogoWrapper }
